<template>
  <div>
    <b-card no-body>
      <div>
        <b-row>
          <b-col class="" cols="6" md="4" sm="12" xs="12">
            <b-form-group>
              <b-input-group class="input-group-merge ml-1 mt-1">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon"/>
                </b-input-group-prepend>
                <b-form-input
                    id="icons-search"
                    v-model="filters['search']"
                    placeholder="Search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class=" " cols="6" md="8" sm="12" xs="12">
            <b-button
                class="shadow-sm  mt-1 mr-1  float-right"
                size=""
                variant="primary" @click="openFileUploadModel()">
              <!--              <feather-icon icon="PlusIcon" size="22"/>-->
              <span>+ Add Document</span>
            </b-button>

          </b-col>
        </b-row>

        <b-row class="p-1">

          <b-col cols="12">
            <b-overlay
                :show="tableLoading"
                rounded="sm"
            >

              <b-table
                  ref="table"
                  :current-page="currentPage"
                  :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="getList"
                  :per-page="pagination.perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="mobile_table_css"

                  hover
                  responsive
              >
                <template v-slot:head(created_at)="data">
                  <span v-b-tooltip.hover.viewport="'Sort'">{{ data.label }}</span>
                </template>

                <template
                    slot="table-row"
                    slot-scope="data"
                ></template>

                >

                <!-- Name $ Avatar -->

                <template #cell(name)="data"

                >
                  <b-col>
                    <b-media no-body>
                      <!--                    <b-form-checkbox-->
                      <!--                        class="align-self-center mr-1"-->
                      <!--                        v-model="checked"-->
                      <!--                        :indeterminate.sync="indeterminate"-->
                      <!--                    >-->
                      <!--                    </b-form-checkbox>-->
                      <b-img
                          v-if="data.item.type === 'pdf' "
                          :src="require('@/assets/images/icons/file-icons/pdf.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'docx' "
                          :src="require('@/assets/images/icons/file-icons/docx.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'doc' "
                          :src="require('@/assets/images/icons/file-icons/docx.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'excel' "
                          :src="require('@/assets/images/icons/file-icons/excel.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'png' "
                          :src="require('@/assets/images/icons/file-icons/png.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'jpg' "
                          :src="require('@/assets/images/icons/file-icons/jpg.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'xls' "
                          :src="require('@/assets/images/icons/file-icons/excel.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <b-img
                          v-if="data.item.type === 'xlsx' "
                          :src="require('@/assets/images/icons/file-icons/excel.png')"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <h6 class="align-self-center my-auto font-weight-bolder">
                        {{ data.item.name }}  
                      </h6>
                    </b-media>
                  </b-col>
                </template>

                <!-- Paid Unpaid Button -->

                <template #cell(action)="data">
                  <b-button
                      v-b-tooltip.hover.top="'Download'"
                      size="23"
                      variant="outline-white"
                      class="btn-icon shadow-sm bg-white text-primary"
                      @click="downloadFile(data.item)"
                  >
                    <feather-icon icon="DownloadIcon" size="18" />
                  </b-button>


                  <b-button
                      v-b-tooltip.hover.top="'Delete'"
                      size="23"
                      variant="outline-white"
                      class="btn-icon shadow-sm bg-white text-danger"
                      @click="deleteFile(data.item.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18"/>
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </b-col>
          <b-col v-if="noDataTable === 0" class="text-center" cols="12">
            <span>No data for preview</span>
          </b-col>


          <!--             Pagination-->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
          </b-col>

          <!-- Pagination -->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-center"
        v-model="showFileUploadModal"
        centered
        hide-footer
        no-close-on-backdrop
        size="lg"
        title="Upload Document"
    >
      <b-overlay
          :show="modalLoading"
          rounded="sm"
      >
        <b-row class="mx-1">
          <b-col class="mt-2" cols="12">
            <div class="m-auto" style="width: 100px ; height: 100px">
              <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                   viewBox="0 0 512 512" x="0px" xml:space="preserve"
                   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
<rect height="512" style="fill:#32BEA6;" width="512" y="0"/>
                <g>
	<polygon points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	" style="fill:#FFFFFF;"/>
                  <polygon points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	" style="fill:#FFFFFF;"/>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
</svg>
            </div>
          </b-col>
          <b-col class="mt-3" cols="12">
            <b-overlay
                :show="buttonLoading"
                rounded="sm"
            >
              <v-select
                  v-model="selected_file_type_id"
                  :options="fileTypeOption"
                  :reduce="fileTypeOption => fileTypeOption.id"
                  label="name"
                  placeholder="Select Document Type"
                  value="id"
                  class="mb-2"
              />

              <b-form-file
                  ref="image_ref"
                  v-model="uploadedFile"
                  drop-placeholder="Drop file here..."
                  placeholder="Choose a file or drop it here..."
              />
            </b-overlay>
          </b-col>
          <b-col class="mt-5" cols="12">
            <div class="float-right">

              <b-button
                  :disabled="isActive"
                  class="mr-2"
                  variant="primary"
                  @click="uploadFile()">

                  Upload
              </b-button>

              <b-button variant="warning" @click="isActive = true , showFileUploadModal = false">Cancel</b-button>
              <!--            <b-button @click="downloadFile()">Download</b-button>-->
              <!--              <b-button @click="getList()">List</b-button>-->
            </div>
          </b-col>
        </b-row>
      </b-overlay>

    </b-modal>
  </div>

</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {baseApiUrl, apiUrl} from '@/constants/config'
import fileUploader from './fileUploader.js'
import profile from '@/apis/modules/profile'
import staffBank from '@/apis/modules/staffBank'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import Filter from '@/mixins/FilterMixin'
import settings from '@/apis/modules/settings'


export default {
  name: 'index',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,

    VBTooltip
  },
  mixins: [MomentMixin, Filter],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      fileTypeOption:[],
      selected_file_type_id:'',
      isActive: true,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters: {},
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'name',
          label: 'DOCUMENT NAME',
          sortable: true
        },
        {
          key: 'file_type',
          label: 'DOCUMENT TYPE',
          sortable: false
        },

        {
          key: 'created_at',
          label: 'DATE UPLOADED',
          sortable: true
        },
        {
          key: 'updated_by',
          label: 'UPLOADED BY',
          sortable: false
        },

        {
          key: 'action',
          label: ''

        }


      ],
      items: []
    }
  },

  computed: {
  },

  watch: {
    uploadedFile () {
      this.isActive = false
    }
  },

  methods: {
    async getFileType () {
      try {
        this.tableLoading = true

        const Response = await settings.getFileType()
        this.fileTypeOption = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
        }))

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async getList () {
      try {
        this.tableLoading = true

        if (this.sortBy === '') {
          this.sortDesc = true
        }

        const Response = await staffBank.getImagesList(this.currentPage, 15, this.filterQuery, this.sortBy, this.sortDesc)
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          updated_by: x.updated_by.first_name + ' ' + x.updated_by.last_name,
          created_at: this.momentFormat(x.created_at, 'dddd DD-MM-YYYY'),
          file_type: x.file_type.name,
          type: x.name.split('.').pop()
        }))


        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }

    },
    async downloadFile (item) {
      try {
        this.tableLoading = true
        // const response =


        const response = await staffBank.downloadImage(item.id)
        axios
            .get(`${apiUrl}/salaried-staff/files/${item.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`, {
              // headers{
              // }
              responseType: 'arraybuffer',
              withCredentials: true
            })
            .then(response => {

              let blob = new Blob([response.data], {type: 'application/pdf'}),
                  // url = window.URL.createObjectURL(blob)
                  // let blob = new Blob([response.data], { type: 'application/pdf' })
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()
              // setTimeout(() => window.URL.revokeObjectURL(link), 100)

              // window.open(url)


              // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
              // let link = document.createElement('a')
              // link.href = window.URL.createObjectURL(blob)
              // link.download = item.name
              // link.click()
            })
        //
        // console.log(response)
        // let blob = new Blob([response.data], { type: 'application/pdf' })
        // let link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = item.name
        // link.click()
        // setTimeout(() => window.URL.revokeObjectURL(link), 100)


        // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // var fileLink = document.createElement('a');
        //
        // fileLink.href = fileURL;
        // // fileLink.href = `http://https://hyre.locumboxapp.com/api/mp/salaried-staff/files/${item.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`;
        // fileLink.target ='blank'
        // fileLink.setAttribute('download', response.headers.content_type);
        // document.body.appendChild(fileLink);
        //
        // fileLink.click();
        //
        //


        // var a = document.createElement("a");
        // a.href = `http://https://hyre.locumboxapp.com/api/mp/salaried-staff/files/${id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`;
        // a.target = 'blank'
        // a.setAttribute("download", 'test');
        // a.click();
        this.showSuccessMessage('File Downloaded Successfully')
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteFile (id) {

      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to delete this document?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
          if (result.value) {
            await staffBank.deleteToFile(id)
            this.$refs.table.refresh()
            this.showSuccessMessage('Your document has been deleted')
          } else if (result.dismiss === 'cancel') {
            this.formLoading = false
            this.showSuccessMessage('Cancelled')
            // this.$swal({
            //   title: 'Cancelled',
            //   text: ' ',

            //   icon: 'error',
            //   customClass: {
            //     confirmButton: 'btn btn-success'
            //   }
            // })
          }
        })

        this.tableLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    resetData() {
      this.selected_file_type_id = ''
      this.uploadedFile = ''
    },

    async uploadFile () {
      try {
        this.buttonLoading = true
        fileUploader.store(this.uploadedFile, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${baseApiUrl}`,

          params: {
            'auth_practice_id': `${sessionStorage.getItem('practice_id')}`
          }
        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
          await this.resetData()
          this.isActive = true
        })

      } catch (error) {
        this.convertAndNotifyError(error)


      }


    },
    async setImageKeyToApi (key) {

      (await staffBank.getImage({
        key: key,
        name: this.uploadedFile.name,
        file_type_id:this.selected_file_type_id,
        description: ""
      }))
      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Document Uploaded Successfully')
      this.buttonLoading = false
      this.$refs.table.refresh()


      this.showFileUploadModal = false


      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },

    openFileUploadModel () {
      try {
        this.showFileUploadModal = true

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    filterQueryUpdate () {
      this.$refs.table.refresh()
    }
  },
  mounted () {
    this.getFileType()
  }

}
</script>

<style scoped>

</style>

<styl lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</styl>
